const ua = {
  language: "ua",
  header: {
    nav: {
      home: "Головна",
      about_us: "Про нас",
      services: "Послуги",
      feedback: "Контакти"
    }
  },
  footer: {
    copyright: "techproengineering © 2022"
  },
  videoBg: {
    list: [
      "Лазерна порізка",
      "листового металу"
    ],
    btnText: "Інші Послуги"
  },
  aboutUs: {
    title: "Про Нас",
    subtitle1: "Ми - Tech",
    subtitle2: "Pro",
    subtitle3: "Engineering",
    text: "Молоді професіонали своєї справи у широкому спектрі послуг",
    btnText: "Дізнатись більше",
    title2: "У нашому парку наявні різноманітні типи обладнань:",
    servicesList: [
      {
        title: "Установка лазерної порізки листового металу"
      },
      {
        title: "Парк оптоволоконних та СО2 гравіювальних лазерів",
        subtitle: "(Працює по різним металам, дереву, шкірі, склі тощо)"
      },
      {
        title: "Обладнання для порошкового фарбування",
        subtitle: "(Здійснюємо фарбування від дисків до різноманітних деталей)"
      },
      {
        title: "Обладнання для шліфування циліндричних поверхонь"
      },
      {
        title: "Склоструменева та піскоструменева камери"
      },
      {
        title: "Ультрафіолетовий принтер",
        subtitle: "(Друк можливий на будь-яких деталях та на усі 360 градусів)"
      },
      {
        title: "Ультразвукова ванна для очистки різних поверхонь"
      }
    ],
    text2: `Використання найсучаснішого обладнання та новітніх розробок дозволяє нам виконувати різнопланові замовлення дуже якісно та швидко. Широкий
      спектр наявного устаткування дає змогу виготовити різноманітні вироби та деталі. 
      Також ми працюємо з серійними замовленнями будь-якої складності для
      забезпечення потреб наших клієнтів у різних галузях промисловості. Ми не обмежуємося стандартними позиціями, готові до креативної співпраці.
      Ознайомитися з нашими роботами ви можете у соц. мережах.`,
    title3: "Маєте запитання?",
    subtitle_3: "Звертайтесь за контактними даними, ми з радістю проконсультуємо Вас!",
    btnText2: "Наші контакти"
  },
  services: {
    title: "Наші послуги",
    list: [
      {
        id: "1",
        url: "lazerna-porizka",
        helmet: {
          title: "Лазерна різка на ЧПУ Рівне, TechPro лазерна порізка листового металу",
          metaContent: "Лазерна порізка на ЧПУ металів, нержавійки, шкіри, пластику, фанери та інших неметалів у м. Рівне та з доставкою по Україні від Tech Pro Engineering."
        },
        title: "Лазерна порізка листового металу",
        mediaType: "video",
        content: [
          "Одним з напрямків нашої роботи є лазерна різка металів та інших матеріалів. Ця послуга включає в себе листову різку нержавійки та інших чорнових металів.",
          "Технологія такої різки має ряд суттєвих переваг в порівнянні з іншими способами різки металу:",
          [
            "- Довільна конфігурація деталі: геометрична форма деталей та рівень складності контуру виробу фактично обмежені лише фантазією дизайнера чи конструктора;",
            "- Якісна поверхня різу, відсутність залишкової деформації на деталях (комп'ютеризація процесу та відсутність механічного впливу дозволяє уникати залишкової деформації контурів різу, а також мінімізувати тимчасові деформації;",
            "- Швидкість та автоматизація процесу різки лазером;",
            "- Висока точність обладнання для лазерної різки (відсутня необхідності подальшого механічного доведення деталі у задані розміри);"
          ],
          "Виробничі параметри нашого станку: ",
          "- Здійснюємо порізку азотом і повітрям;",
          "- Сталь до 16 мм;",
          "- Нержавіюча сталь до 8 мм;",
          "- Робоче поле столу 3000 х 1500 мм;",
          "- Точність різу +- 0,1мм;",
          "Іншим напрямком нашої роботи є різка неметалевих матеріалів, що включає в себе різку поролону, шкіри, акрилу, пластику, фанери тощо.",
          "TechProEngineering надає послуги лазерної різки металу, шкіри, фанери та інших матеріалів у м. Сарни, Рівненської області та з доставкою по всій території України."
        ],
        feedbackText1: "Вартість послуги лазерної різки розраховується індивідуально. Ви можете скористатися нашою ",
        feedbackLink: "формою для зворотного зв'язку",
        feedbackText2: " та надіслати нам інформацію для прорахунку вказавши вид різки і матеріалу, який вас цікавить. Наш менеджер обов'язково зв'яжеться з вами.",
        phoneText: "Або ж Ви можете самостійно звернутися з цим та іншими питаннями за телефоном:",
        emailText: "Email для відправки креслень:"
      },
      {
        id: "2",
        url: "lazerne-graviyuvanya",
        helmet: {
          title: "Лазерне гравіювання, нанесення тексту, зображення, лого на метали та неметали TechPro Рівне",
          metaContent: "Декоративне лазерне гравіювання, якісне нанесення інформації, тексту, зображення, лого на метал, нержавійку, шкіру, гуму, кераміку, скло. Швидке та якісне гравіювання лазером у Рівненській області та з доставкою по Україні від Tech Pro Engineering."
        },
        title: "Лазерне гравіювання",
        mediaType: "image",
        content: [
          "Основним напрямком діяльності нашої компанії є надання послуг лазерного гравіювання по металам та неметалам.",
          "Широкий спектр обладнання з лазерного гравіювання дає нам змогу наносити будь-яку інформацію у вигляді тексту, зображення чи лого на метал, пластик, шкіру, дерево, кераміку, гуму, тканину, скло тощо та швидко і якісно опрацьовувати великі гуртові замовлення.",
          "Зображення, отримане за допомогою лазерного гравіювання, відрізняє довговічність і зносостійкість, а завдяки високій керованості процесів обробки, можливо отримати як легке поверхневе маркування, так і глибоке лазерне гравіювання. Лазерні гравери знайшли широке застосування в сувенірній і рекламній продукції, а також на виробничих підприємствах.",
          "TechProEngineering надає послуги лазерного гравіювання металу, шкіри, кераміки, нержавійки та інших матеріалів у м. Сарни, Рівненської області та з доставкою по всій території України.",
          "Вартість послуги лазерного гравіюваня розраховується індивідуально."
        ],
        feedbackText1: "Ви можете скористатися нашою ",
        feedbackLink: "формою для зворотного зв'язку",
        feedbackText2: " та надіслати нам інформацію для прорахунку вказавши вид гравіювання та матеріалу. Наш менеджер обов'язково зв'яжеться з вами.",
        phoneText: "Або ж Ви можете самостійно звернутись з цим та іншими питаннями за телефоном:",
        emailText: "Email для відправки креслень:"
      },
      {
        id: "3",
        url: "poroshkove-farbuvanya",
        helmet: {
          title: "Промислове фарбування, порошкове фарбування дисків, металів у Рівному TechPro",
          metaContent: "Широка гама кольорів та спецефектів порошкового фарбування дисків, металу, обладнання у Рівненській області та з доставкою по Україні від Tech Pro Engineering."
        },
        title: "Порошкове фарбування",
        mediaType: "video",
        content: [
          "Промислове фарбування, що здійснюється методом порошкового фарбування - є одним із багатьох напрямків нашої діяльності. Зокрема, головною спеціалізацією даного виду послуг є фарбування саме автомобільних дисків.",
          "Порошкове фарбування здійснюється за допомогою електростатичного напилення заряджених часточок порошку на поверхню металу. Під час теплової обробки у печі, при температурі 180-220С, порошкова фарба плавиться і утворюється твердий шар – полімерне покриття.",
          "Після фарбування поверхня виробу отримує характеристики, яких неможливо досягнути при застосуванні звичайних технологій:",
          [
            "- Атмосферостійкість та опір дії ультрафіолетових променів;",
            "- Хімічна стійкість до розчинів багатьох кислот, лугів, нафтопродуктів та деяких органічних розчинників;",
            "- Висока міцність, ударостійкість, еластичність;",
            "- Широка гама кольорів і спецефектів, що поєднує в собі захисні та декоративні властивості."
          ],
          "Розміри нашої порошкової камери: 1500x1000x1000 см.",
          "Найкращий результат фарбування ми отримуємо здійснивши попередню технологічну обробку поверхні. На підприємстві ви можете обрати необхідний процес, який дозволить максимально підготувати вироби до фарбування.",
          "TechProEngineering надає послуги якісного порошкового фарбування автомобільних дисків та інших металів у м. Сарни, Рівненської області та з доставкою по всій території України.",
          "Вартість послуги порошкового фарбування розраховується індивідуально."
        ],
        phoneText: "З усіх питань звертайтесь за телефоном, наші менеджери охоче допоможуть Вам:",
        emailText: "Email для зв’язку:"
      },
      {
        id: "4",
        url: "sklostrumeneva-piskostrumenneva-obrobka",
        helmet: {
          title: "Склоструменева та піскоструменева обробка металу TechPro Рівне",
          metaContent: "Делікатна очистка та шліфування металу, склоструменева та піскоструменева обробка без пошкодження різьблень та поверхонь у Рівненській області та з доставкою по Україні від Tech Pro Engineering."
        },
        title: "Склоструменева та піскоструменева обробка",
        mediaType: "image",
        content: [
          "Пропонуємо послуги склоструменевої та піскоструменевої обробки, що є одними з фінішних обробок поверхонь металевих деталей. Суть склоструменевої обробки полягає в бомбардуванні оброблюваної поверхні скляними кульками різного діаметру, що в результаті дає матовану рівномірну поверхню. Окрім обробки металевих поверхонь склоструменева обробка застосовується для обробки скла, каменю, пластику тощо.",
          "Делікатність такої обробки дозволяє очищати матеріали не пошкоджуючи різьблення та посадкові поверхні.",
          "Піскоструменева обробка металу - це спосіб очистки металевих поверхонь абразивним потоком, який виконує роль шліфувального засобу. Такий процес має подвійну функцію: очищає поверхню і надає їй шорсткості.",
          "Ви можете самостійно обрати тип обробки, який найбільше Вас цівавить, та зв’язатись з нами.",
          "TechProEngineering надає послуги склоструменевої та піскоструменевої обробки деталей, металевих поверхонь у м. Сарни, Рівненської області та з доставкою по всій території України."
        ],
        phoneText: "З усіх питань звертайтесь за телефоном, наші менеджери охоче допоможуть Вам:",
        emailText: "Email для зв’язку:"
      },
      {
        id: "5",
        url: "shlifuvanya-turbogaltuvanya",
        helmet: {
          title: "Високоточна метелообробка, шліфування, турбогалтування, лазерна різка та гравіювання TechPro Рівне",
          metaContent: "Комплексна високоточна металообробка у м. Рівне, безцентрове шліфування та полірування металів, турбогалтування металевих виробів у Рівненській області та з доставкою по Україні від Tech Pro Engineering."
        },
        title: "Шліфування циліндричних поверхонь",
        mediaType: "video",
        content: [
          "Наша компанія також надає послуги безцентрового шліфування та полірування металевих виробів. Цей метод має досить високі показники ефективності та високу точність обробки. Безцентрове шліфування, здійснюване без затиску і без пристроїв примусового обертання деталі, не вимагає створення центрових базових гнізд і спрощує автоматизацію обробки.",
          "Ми надаємо наступні послуги:",
          [
            "- Шліфування плоских поверхонь;",
            "- Шліфування циліндричних поверхонь."
          ],
          "TECHPRO ENGINEERING виконує також галтування і полірування деталей з чорної та нержавіючої сталі, титану та латуні в галтувальних вібромашинах.",
          "Головне застосування в якості фінішної обробки деталей з різних металів, в результаті чого можна отримати практично дзеркальну поверхню.",
          "TechProEngineering надає послуги шліфування металу,турбогалтування і полірування деталей у м. Сарни, Рівненської області та з доставкою по всій території України."
        ],
        phoneText: "З усіх питань звертайтесь за телефоном:",
        emailText: "Email для зв’язку:"
      },
      {
        id: "6",
        url: "ultrazvukove-ochyschenya",
        helmet: {
          title: "Ультразвукове очищення забруднень будь-якої складності TechPro Рівне",
          metaContent: "Очищення забруднень будь-якої складності, знежирення, зневуглецювання та видалення накипу з найбільш важкодоступних місць деталей та коспонентів в ультразвуковій промисловій ванні. Ультразвукове очищення у Рівненській області та з доставкою по Україні від Tech Pro Engineering."
        },
        title: "Ультразвукове промислове очищення",
        mediaType: "image",
        content: [
          "Ультразвукова промислова ванна для очищення деталей є найкращим інструментом для знежирення, зневуглецювання та видалення накипу для всіх деталей та компонентів.",
          "Ультразвукове очищення є запорукою досягнення найкращих результатів навіть в найбільш важкодоступних місцях, незалежно від їх складності та конфігурації.",
          "Основні переваги чистки деталей ультразвуком:",
          [
            "- Видалення усіх видів забруднень, зокрема олива, жирів, нагару, іржі, захисні емалі та інші;",
            "- Швидке, ефективне та дбайливе очищення, відсутня поява механічних пошкоджень та подряпин;",
            "- Очистка ультразвуком є безпечною для всіх видів матеріалів, не чинить механічного впливу на поверхню очищуваних деталей."
          ],
          "TechProEngineering надає послуги ультразвукової очистки деталей від забруднень у м. Сарни, Рівненської області та з доставкою по всій території України."
        ],
        phoneText: "З усіх питань звертайтесь за телефоном:",
        emailText: "Email для зв’язку:"
      },
      {
        id: "7",
        url: "ultrafioletovyy-druk",
        helmet: {
          title: "Чіткий та якісний ультрафіолетовий друк на плоских, циліндричних поверхнях TechPro Рівне",
          metaContent: "Надійний, чіткий та якісний ультрафіолетовий друк на дереві, фанері, ЛДСП, склі, кераміці та інших матеріалах. Ультрафіолетовий друк виконуємо на плоских, циліндричних поверхнях у Рівненській області та з доставкою по Україні від Tech Pro Engineering."
        },
        title: "Ультрафіолетовий друк",
        mediaType: "video",
        content: [
          "Унікальна послуга, яку ми надаємо – це ультрафіолетовий друк. У нашому парку наявний ультрафіолетовий принтер останнього покоління з додатковою установкою, що дає можливість наносити надзвичайно чітке та якісне зображення не лише на плоских поверхнях, а й на циліндричних на усі 360 градусів!",
          "Друк не втрачає якості, кольору та надійно тримається на майже усіх матеріалах (дерево, фанера, ЛДСП, МДФ, скло, пластик, акрил, композит, камінь, полотно, кераміка).",
          "Ви можете замовити УФ-друк на таких поверхнях:",
          [
            "- Дереві, фанері, ЛДСП, МДФ (столи, шафи-купе, кухонні шафки, вивіски та ін.);",
            "- Склі (кухонні фартухи (скіналі), двері, вітражі, меблі, міжкімнатні перегородки та ін.);",
            "- Пластиці, акрилі, композиті (рекламна продукція - стенди, вітрини, вивіски та ін.);",
            "- Камені (панно, фрески, фасади будівель та ін.);",
            "- Шпалерах (фотошпалери різної текстури для різних типів приміщень);",
            "- Полотні (картини для дитячої кімнати, офісу, вітальні, кухні);",
            "- Кераміці (плитка та ін.);",
            "- Силіконі (чохли на телефони)."
          ],
          "TechProEngineering надає послуги ультрафіолетового друку на дереві, склі, пластику, силіконі та інших матеріалах у м. Сарни, Рівненської області та з доставкою по всій території України."
        ],
        phoneText: "З усіх питань звертайтесь за телефоном:",
        emailText: "Email для відправки креслень та ваших ілюстрацій:"
      }
    ]
  },
  ordering: {
    title: "Оформлення замовлення",
    btnText: "Замовити зараз",
    text1: "Початок роботи над замовленням відбувається лише після обговорення та уточнення усіх деталей та Ваших побажань.",
    text2: "Терміни виконання замовлення залежать від наступних факторів:",
    list2: [
      "Тип роботи",
      "Навантаженість виробництва",
      "Наявність необхідних креслень та (або) моделей"
    ],
    list3: [
      "У свою чергу ми намагатимемося виконати Ваше замовлення якнайшвидше.",
      "Якщо у Вас немає макету чи креслень, наші спеціалісти допоможуть вам у цьому та підготують усі необхідні матеріали до виробництва.",
      "Приймаємо замовлення по всій Україні.",
      "ВІДПРАВКА ВИКОНАНИХ ЗАМОВЛЕНЬ можлива будь-якими ТРАНСПОРТНИМИ КОМПАНІЯМИ."
    ]
  },
  qa: {
    title: "Часті запитання",
    questions: [
      {
        title: "Як відбувається процес оформлення замовлення? ",
        list1: [
          "Після контакту з нашими спеціалістами (за допомогою мейлу, телефону, соцмереж тощо), обговорення усіх деталей і попереднього прорахунку Вашого замовлення, затвердження креслень, повної предоплати замовлення, ми передаємо усі данні на виробництво для виконання вашого замовлення."
        ]
      },
      {
        title: "Як довго очікувати на виконання мого замовлення?",
        list1: [
          "Терміни виконання замовлення залежать від типу роботи, серійності, навантаженості виробництва, наявності необхідних креслень та моделей.",
          "В свою чергу ми гарантуємо швидке виконання вашого замовлення"
        ]
      },
      {
        title: "Чи можливо оформити замовлення без готових креслень, моделей, ілюстрацій?",
        list1: [
          "Ми маємо штатного інженер-конструктора, дизайнера. За додаткову плату залюбки створимо Вам робоче креслення та дизайн замовлення."
        ]
      },
      {
        title: "Як відбуваєтьс доставка?",
        list1: [
          "Доставляємо виконані замовлення по території України: Самовивіз/ Нова Почта/ Міст Експрес/ Делівері."
        ]
      } 
    ]
  },
  socials: {
    title: "Ми в соцмережах"
  },
  feedback: {
    title: "Зворотній зв'язок",
    subtitle: "Щоб надіслати email з додатковими файлами - перейдіть за",
    subtitleLink: "посиланням",
    btnText: "Відправити",
    select_files: "Обрати файл",
    inputs: [
      {
        name: "name",
        type: "text",
        placeholder: "Ім'я та прізвище"
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email"
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Номер телефону"
      },
      {
        name: "town",
        type: "text",
        placeholder: "Місто"
      },
      {
        name: "service",
        type: "checkbox",
        placeholder: "Вибір послуги",
        options: [
          "Лазерна позірка металів та інших матеріалів",
          "Лазерне гравіювання",
          "Порошкове фарбування",
          "Склоструменева та піскоструменева обробка",
          "Шліфування циліндричних поверхонь",
          "Ультрафіолетовий Друк"
        ]
      },
      {
        name: "comment",
        type: "textarea",
        placeholder: "Коментар"
      }
    ]
  },
  contacts: {
    title: "Контактні дані",
    list: [
      {
        title: "Номер телефону",
        href: "tel:+380501000845",
        icon: "icon-phone",
        content: [
          "+38 (050) 100-08-45"
        ]
      },
      {
        title: "Email для контакту та креслень",
        href: "mailto:support@techproengineering.com.ua",
        icon: "icon-email",
        content: [
          "support@techproengineering.com.ua"
        ]
      },
      {
        title: "Адреса",
        href: "https://www.google.es/maps/place/%D0%91%D1%94%D0%BB%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D1%8C%D0%BA%D0%B0,+13,+%D0%A1%D0%B0%D1%80%D0%BD%D0%B8,+%D0%A0%D1%96%D0%B2%D0%BD%D0%B5%D0%BD%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D1%97%D0%BD%D0%B0,+34500/@51.3318731,26.6152898,17z/data=!3m1!4b1!4m5!3m4!1s0x4728a291e9b2eeb9:0x72ecc9915fb54029!8m2!3d51.3318731!4d26.6174784",
        icon: "icon-location",
        content: [
          "Україна, Рівненська область,",
          "м. Сарни, вул. Європейська 13"
        ]
      }
    ]
  }
}

export default ua
